import config from "@config/index";
import { keccak256 as ethersKeccak256, parseEther, toUtf8Bytes } from "ethers";
import { useCallback } from "react";

import { useRoyaltySignature } from "@api/contract/hooks";

import { useWalletState } from "@Web3/context";
import { WalletErrorHandler } from "@Web3/utils";

import useDecentralverseCollectionContract from "@contracts/hooks/useDecentralverseCollectionContract";

const keccak256 = (value) => ethersKeccak256(toUtf8Bytes(value));

const useSignSaleData = ({ collectionAddress }) => {
  const { signer, chainId } = useWalletState();
  const collectionContract = useDecentralverseCollectionContract({
    contractAddress: collectionAddress,
  });
  const { mutateAsync: generateRoyaltySignature } = useRoyaltySignature();

  const signSaleData = useCallback(
    async ({ tokenId, price, nonce, royaltyRecipient, royaltyPercentage }) => {
      try {
        if (!signer) {
          throw new Error("Wallet not connected");
        }

        if (!collectionAddress) {
          throw new Error("NFT contract not connected");
        }

        const verifierContract = config.contracts.dcvExchangeAddress;

        const isApproved = await collectionContract.isApprovedForAll(
          signer.address,
          verifierContract
        );

        if (!isApproved) {
          await collectionContract.setApprovalForAll(verifierContract, true);
        }

        const isSaleWithRoyalty = royaltyRecipient && royaltyPercentage;

        const baseTypes = [
          { name: "orderNonce", type: "uint256" },
          { name: "nftContract", type: "address" },
          { name: "tokenId", type: "uint256" },
          { name: "price", type: "uint256" },
        ];

        const signData = {
          domain: {
            name: "Dcentralverse Exchange",
            version: "1",
            chainId,
            verifyingContract: verifierContract,
            salt: keccak256("Dcentralverse Exchange Salt"),
          },
          types: {},
          value: {
            orderNonce: nonce,
            nftContract: collectionAddress,
            tokenId: tokenId,
            price: parseEther(price),
          },
        };

        if (isSaleWithRoyalty) {
          signData.types.SaleWithRoyalty = [
            ...baseTypes,
            { name: "royaltyRecipient", type: "address" },
            { name: "royaltyPercentage", type: "uint256" },
          ];

          signData.value.royaltyRecipient = royaltyRecipient;
          signData.value.royaltyPercentage = royaltyPercentage * 100;

          const { royaltySignature } = await generateRoyaltySignature({
            tokenId,
            nftContract: collectionAddress,
            verifierContract,
            royaltyRecipient,
            royaltyPercentage,
          });

          const signature = await signer.signTypedData(
            signData.domain,
            signData.types,
            signData.value
          );

          return {
            signature,
            royaltySignature,
          };
        }

        signData.types.Sale = baseTypes;

        const signature = await signer.signTypedData(
          signData.domain,
          signData.types,
          signData.value
        );

        return {
          signature,
        };
      } catch (error) {
        const errorMessage = WalletErrorHandler(error);
        throw new Error(errorMessage);
      }
    },
    [
      signer,
      collectionAddress,
      collectionContract,
      chainId,
      generateRoyaltySignature,
    ]
  );

  return {
    signSaleData,
  };
};

export default useSignSaleData;
