import axiosInstance from "@api/axiosInstance";

const assetService = {
  getAssetTypes() {
    return axiosInstance.get("/assets/types");
  },
  getAssetTypeShares() {
    return axiosInstance.get("/assets/type-shares");
  },
  getAssets() {
    return axiosInstance.get("/assets");
  },
  getFeaturedAssets() {
    return axiosInstance.get("/assets/featured");
  },
  getAsset(identifier) {
    return axiosInstance.get(`/assets/${identifier}`);
  },
  createAsset(data) {
    return axiosInstance.post("/assets", data);
  },
  updateAsset({ identifier, data }) {
    return axiosInstance.put(`/assets/${identifier}`, data);
  },
  updateAssetPosition({ identifier, data }) {
    return axiosInstance.put(`/assets/${identifier}/position`, data);
  },
  deleteAsset(identifier) {
    return axiosInstance.delete(`/assets/${identifier}`);
  },
  create3DAssetTileset(data) {
    return axiosInstance.post("/assets/3d-tileset", data);
  },
  delete3DAssetTileset(identifier) {
    return axiosInstance.delete(`/assets/3d-tileset/${identifier}`);
  },
  getAssetTillingStatus(assetTileId) {
    return axiosInstance.get(`/assets/tilling-status/${assetTileId}`);
  },
};

export default assetService;
