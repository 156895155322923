import PropTypes from "prop-types";
import { Suspense } from "react";

import { PageLoader } from "@components/Loader";

const SuspenseWrapper = ({ children, fallback }) => {
  return (
    <Suspense fallback={fallback || <PageLoader text="Loading..." />}>
      {children}
    </Suspense>
  );
};

SuspenseWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  fallback: PropTypes.node,
};

export default SuspenseWrapper;
