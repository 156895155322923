import { useQuery } from "@tanstack/react-query";

import assetService from "../services";

const useAssetsQuery = () => {
  return useQuery({
    queryKey: ["assets"],
    queryFn: () => assetService.getAssets(),
  });
};

export default useAssetsQuery;
