import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { fileService } from "@api/services";

import { getFileInfo } from "@shared/helpers/file";

const uploadFileToS3 = async ({ file, presignedUrl }) => {
  await axios.put(presignedUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
};

const useUploadFileToS3 = () => {
  return useMutation({
    mutationFn: async ({
      fileNamePredefined,
      originalFile,
      fileToUpload,
      category,
    }) => {
      const { fileName, fileType } = getFileInfo(originalFile || fileToUpload);

      const { presignedUrl } = await fileService.getPresignedUrl({
        fileName: fileNamePredefined || fileName,
        fileType,
        category,
      });

      if (presignedUrl) {
        await uploadFileToS3({ file: fileToUpload, presignedUrl });
        return presignedUrl.split("?")[0];
      } else {
        throw new Error("Presigned URL is invalid or undefined");
      }
    },
  });
};

export default useUploadFileToS3;
