// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AisLsJaE {
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  white-space: nowrap;
  flex: 1;
  gap: 0.75rem;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 100%;
}

.jgIdX0Bz {
  border: none !important;
  background: linear-gradient(90deg, #ff0691 7.11%, #fe1650 100%);
  color: #fff;
}

.Gz8MeVw9 {
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #fff;
}

.AisLsJaE:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.AisLsJaE:active {
  transform: scale(0.98);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `AisLsJaE`,
	"main": `jgIdX0Bz`,
	"outlined": `Gz8MeVw9`
};
module.exports = ___CSS_LOADER_EXPORT___;
