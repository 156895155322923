// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.walletconnect-modal__base {
  max-height: 593px;
  height: 100%;
}
.walletconnect-modal__base a {
  color: #000000;
  text-transform: uppercase;
}
.walletconnect-modal__footer {
  margin-top: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/walletConnectModal.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".walletconnect-modal__base {\n  max-height: 593px;\n  height: 100%;\n}\n.walletconnect-modal__base a {\n  color: #000000;\n  text-transform: uppercase;\n}\n.walletconnect-modal__footer {\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
