import "cesium/Build/Cesium/Widgets/widgets.css";
import { useCallback, useEffect } from "react";

import { useAssetsQuery } from "@api/asset/hooks";
import { usePolygonsQuery } from "@api/polygon/hooks";
import { queryClient } from "@api/queryClient";

import { useAssets } from "@features/Asset/hooks";
import FeaturedEntitiesModal from "@features/FeaturedEntitiesModal";

import SwitchPlanet from "@components/SwitchPlanet";

import { useWebSocket } from "@shared/hooks";

import { useCesiumApp } from "../context/CesiumProvider";
import { useCameraPosition, usePolygons } from "../hooks";
import { flyToLand } from "../utils";
import styles from "./ViewerContainer.module.css";

const ViewerContainer = () => {
  const { viewer, showEntities, cesiumContainerRef } = useCesiumApp();

  const { toggleAssetsVisibility, loadDatabaseAssetsInViewer } = useAssets(
    viewer,
    showEntities.assets
  );
  const { togglePolygonsVisibility, loadDatabasePolygonsInViewer } =
    usePolygons(viewer);

  const { data: polygons } = usePolygonsQuery();
  const { data: assets } = useAssetsQuery();

  useEffect(() => {
    loadDatabasePolygonsInViewer(polygons);
    loadDatabaseAssetsInViewer(assets, polygons);
  }, [
    assets,
    loadDatabaseAssetsInViewer,
    loadDatabasePolygonsInViewer,
    polygons,
  ]);

  useEffect(() => {
    togglePolygonsVisibility(showEntities.polygons);
    toggleAssetsVisibility(showEntities.assets);
  }, [showEntities, toggleAssetsVisibility, togglePolygonsVisibility]);

  useCameraPosition(viewer, polygons);

  const handleNftUpdate = useCallback((data) => {
    queryClient.invalidateQueries([`${data?.nftType}s`, data?.nftTypeId]);
    queryClient.invalidateQueries(["nfts", data?.nftId]);
    queryClient.invalidateQueries(["owned-nfts"]);
    queryClient.invalidateQueries(["trade-history"]);
  }, []);

  const events = {
    nftUpdate: handleNftUpdate,
  };

  useWebSocket(events);

  const handleFeaturedAssetClick = (asset) => {
    if (asset?.landVertices) {
      flyToLand(viewer, asset.landVertices);
    }
  };

  return (
    <>
      <div
        id="cesiumContainer"
        className={styles.cesiumContainer}
        ref={cesiumContainerRef}
      />
      <FeaturedEntitiesModal
        handleFeaturedAssetClick={handleFeaturedAssetClick}
      />
      <SwitchPlanet />
    </>
  );
};

export default ViewerContainer;
