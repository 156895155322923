// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eE81hBbN > div {
  max-width: 600px;
}

.LZ8g5JFq {
  display: flex;
  flex-direction: row;
}

.vkdpkrqo {
  max-width: 78%;
  width: 100%;
  padding: 0 25px;
}

.vkdpkrqo fieldset:nth-of-type(4) {
  margin-bottom: 0;
}

.mzK_SKUy {
  position: relative;
  display: flex;
  flex-direction: row;
}

.aszhhScQ {
  margin: 0;
  line-height: 18.6px;
}

._VlvZJzu {
  position: absolute;
  right: 0;
  top: 45%;
  z-index: 2;
  cursor: pointer;
}

.aszhhScQ {
  margin: 0;
  line-height: 18.6px;
}

._VlvZJzu {
  position: absolute;
  right: 0.5rem;
}

.ItUhkDw9 {
  width: auto;
  margin-left: auto;
  padding-left: 2rem;
  padding-right: 2rem;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountModal": `eE81hBbN`,
	"accountBody": `LZ8g5JFq`,
	"containerBodyRightForm": `vkdpkrqo`,
	"accountWalletAddressRoot": `mzK_SKUy`,
	"accountWalletAddress": `aszhhScQ`,
	"accountWalletAddressCopyIcon": `_VlvZJzu`,
	"accountFooterButton": `ItUhkDw9`
};
module.exports = ___CSS_LOADER_EXPORT___;
