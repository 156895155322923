// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._7uPM9gcZ {
  width: 70%;
}

.k5I7qT_5 {
  font-family: "Inter", sans-serif;
  font-size: 0.625rem;
  color: #14b7ff;
}

.jx7loqJM {
  display: flex;
  justify-content: center;
  max-width: 192px;
  margin: 0 auto;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"featuredModal": `_7uPM9gcZ`,
	"featuredHighlight": `k5I7qT_5`,
	"featuredFooter": `jx7loqJM`
};
module.exports = ___CSS_LOADER_EXPORT___;
