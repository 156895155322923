// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q_QPifkX > div {
  max-width: 338px;
  width: 100%;
}

.q_QPifkX > div > div:first-of-type {
  padding: 1rem;
}

.q_QPifkX > div > div:last-of-type {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2rem;
  margin-top: 0;
}

.xUdLTo6F {
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
}

.QPAtKVw6 {
  text-align: center;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 800;
  line-height: 22px;
}

.zzzNlsMx {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.cQx19Jdr {
  padding: 1rem 2.5rem;
  height: 46px;
  width: 172px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #010519;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  white-space: nowrap;
  border: none;
  background: #ff0691;
  background: linear-gradient(90deg, #ff0691 7.11%, #fe1650 100%);
  border-radius: 8px;
}

.qiViakKS {
  background: transparent;
  border: 1px solid #ffffff;
  color: #fff;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `q_QPifkX`,
	"image": `xUdLTo6F`,
	"text": `QPAtKVw6`,
	"ctaButtons": `zzzNlsMx`,
	"button": `cQx19Jdr`,
	"cancelButton": `qiViakKS`
};
module.exports = ___CSS_LOADER_EXPORT___;
