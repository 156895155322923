import { useQuery } from "@tanstack/react-query";
import { formatUnits } from "ethers";

import { Web3QueryKeys } from "@Web3/constants";
import { useWalletState } from "@Web3/context/WalletContext";
import { convertMaticToUsd } from "@Web3/utils";

import useErc20Contract from "@contracts/hooks/useErc20Contract";

import { formatCurrency } from "@shared/helpers/formatCurrency";

import useMaticToUsdRate from "./useMaticToUsdRate";

const DECIMALS = 18;

async function fetchErc20Balance(
  erc20Contract,
  currentAddress,
  maticToUsdRate
) {
  try {
    const erc20BalanceWei = await erc20Contract.balanceOf(currentAddress);
    const erc20Balance = formatUnits(erc20BalanceWei, DECIMALS);

    const usdRate = convertMaticToUsd(erc20Balance, maticToUsdRate);

    return {
      weth: parseFloat(erc20Balance),
      wei: erc20BalanceWei,
      formatted: formatCurrency(erc20Balance),
      usd: formatCurrency(usdRate),
    };
  } catch (error) {
    console.error("Error fetching ERC20 balance", error);
    return null;
  }
}

const useErc20Balance = () => {
  const { currentAddress, isCorrectChain } = useWalletState();
  const erc20Contract = useErc20Contract();
  const { data: maticToUsdRate } = useMaticToUsdRate();

  return useQuery({
    queryKey: [Web3QueryKeys.ERC20_BALANCE],
    queryFn: () =>
      fetchErc20Balance(erc20Contract, currentAddress, maticToUsdRate),
    enabled:
      !!currentAddress && isCorrectChain && !!erc20Contract && !!maticToUsdRate,
  });
};

export default useErc20Balance;
