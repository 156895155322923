// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-color-blue {
  color: #549fc8;
}

.modal-color-pink {
  color: #ff0691;
}

.modal-link {
  text-decoration: none;
}

.modal-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.modal-footer {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 60px;
}

.modal-footer-cancel-button {
  outline: none;
  background: none;
  border: none;
  box-sizing: border-box;
  padding: 5px 16px;
  gap: 10px;
  width: 90px;
  height: 36px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/styles/components/modal.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,oBAAoB;EACpB,qBAAqB;EACrB,mBAAmB;EACnB,SAAS;EACT,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,sBAAsB;EACtB,iBAAiB;EACjB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".modal-color-blue {\n  color: #549fc8;\n}\n\n.modal-color-pink {\n  color: #ff0691;\n}\n\n.modal-link {\n  text-decoration: none;\n}\n\n.modal-link:hover {\n  text-decoration: underline;\n  cursor: pointer;\n}\n\n.modal-footer {\n  display: flex;\n  justify-content: end;\n  align-items: flex-end;\n  flex-direction: row;\n  gap: 10px;\n  width: 100%;\n  height: 60px;\n}\n\n.modal-footer-cancel-button {\n  outline: none;\n  background: none;\n  border: none;\n  box-sizing: border-box;\n  padding: 5px 16px;\n  gap: 10px;\n  width: 90px;\n  height: 36px;\n  border: 1px solid #ffffff;\n  border-radius: 8px;\n  font-family: \"Inter\", sans-serif;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  line-height: 22px;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
