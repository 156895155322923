// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wU6yDjSB {
  flex: 1 0 25%;
}

.Qrexk2Bm {
  display: inline-block;
  width: 142px;
  height: 142px;
}

.CghDvX8R {
  object-fit: cover;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.LTRwFweX {
  display: none;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"uploadImageContainer": `wU6yDjSB`,
	"uploadImageLabel": `Qrexk2Bm`,
	"uploadImagePreview": `CghDvX8R`,
	"uploadImageInput": `LTRwFweX`
};
module.exports = ___CSS_LOADER_EXPORT___;
