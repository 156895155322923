import { useMutationData } from "@api/hooks";

import userService from "../services";

const useUpdateCurrentUser = () => {
  return useMutationData(userService.updateUser, {
    invalidateQueries: [["me"], ["trade-history"], ["lands"], ["assets"]],
  });
};

export default useUpdateCurrentUser;
