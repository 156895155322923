// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Enrckx_o {
  vertical-align: middle;
}

.C0XFS0Qn {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #cbd5e0;
  margin-left: 0.5rem;
  transform: scale(1.5);
  margin-bottom: 30px;
}

.vODsGneC::after {
  content: "*";
  padding-left: 0.05rem;
  color: #ff0691;
}

.T6EOxFkF {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
}

.g5YoHkWy {
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: red;
  line-height: 10px;
  padding: 0.5rem;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkboxInput": `Enrckx_o`,
	"checkboxLabel": `C0XFS0Qn`,
	"required": `vODsGneC`,
	"errorContainer": `T6EOxFkF`,
	"error": `g5YoHkWy`
};
module.exports = ___CSS_LOADER_EXPORT___;
