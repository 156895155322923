// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZeYgEG2h {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.hTXcZevG {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: #fff;
  background: #010519;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  overflow: hidden;
  min-width: 300px;
  padding: 1rem;
  width: 100%;
}

.taMY9eg8 {
  font-family: "Lato", sans-serif;
  font-size: 28px;
  font-weight: 800;
  line-height: 36px;
  margin: 0;
}

.dxq6FQVB {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  line-height: 2rem;
}

.bnwOIhKL .taMY9eg8 {
  font-size: 1.25rem;
  line-height: 1.5rem;
}
.GXL7HlUw .taMY9eg8 {
  font-size: 1.75rem;
  line-height: 2.5rem;
}

.WYHqN9iS {
  position: absolute;
  right: 1rem;
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  transition: transform 0.25s;
  margin-left: 2rem;
}

.WYHqN9iS:hover {
  cursor: pointer;
  transform: scale(1.3);
}

.dMoOP68c {
  object-fit: contain;
}

.ySm3U9pA {
  margin-top: 1.25rem;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOverlay": `ZeYgEG2h`,
	"modal": `hTXcZevG`,
	"title": `taMY9eg8`,
	"modalHeading": `dxq6FQVB`,
	"small": `bnwOIhKL`,
	"large": `GXL7HlUw`,
	"modalCloseButton": `WYHqN9iS`,
	"modalCloseImg": `dMoOP68c`,
	"modalContent": `ySm3U9pA`
};
module.exports = ___CSS_LOADER_EXPORT___;
