/* eslint-disable no-unused-vars */
function isNotNullOrUndefinedOrFalse(value) {
  return value !== null && value !== undefined && value !== false;
}

export const clsx = (...args) =>
  Array.from(
    new Set(
      args.filter(isNotNullOrUndefinedOrFalse).reduce((result, value) => {
        if (typeof value === "object" && !Array.isArray(value)) {
          return result.concat(
            Object.entries(value)
              .filter(([_, v]) => v)
              .map(([k]) => k)
          );
        }
        if (Array.isArray(value)) {
          return result.concat(clsx(...value).split(" "));
        }
        return result.concat(String(value));
      }, [])
    )
  )
    .join(" ")
    .trim();
