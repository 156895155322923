export const ModalTypes = {
  NONE: "NONE",
  LAND_INFO: "LAND_INFO",
  ASSET_INFO: "ASSET_INFO",
  MINT_ASSET: "MINT_ASSET",
  CREATE_ASSET: "CREATE_ASSET",
  EDIT_ASSET: "EDIT_ASSET",
  ASSET_ADJUSTMENT: "ASSET_ADJUSTMENT",
};

export const ModalTradeTypes = {
  NONE: "NONE",
  LIST_FOR_SALE: "LIST_FOR_SALE",
  CANCEL_LISTING: "CANCEL_LISTING",
  MAKE_OFFER: "MAKE_OFFER",
  ACCEPT_OFFER: "ACCEPT_OFFER",
  BUY_NOW: "BUY_NOW",
  COMPLETE_SALE_MODAL: "COMPLETE_SALE_MODAL",
  MINT_FIRST_MODAL: "MINT_FIRST_MODAL",
  MINT_PROCESS_MODAL: "MINT_PROCESS_MODAL",
  NO_OFFER_ALLOWED: "NO_OFFER_ALLOWED",
  TRADE_ERROR_MODAL: "TRADE_ERROR_MODAL",
  MINT_ASSET_CONFIRM_MODAL: "MINT_ASSET_CONFIRM_MODAL",
};

export const Sidebars = {
  NONE: "NONE",
  TRADE_LAND_SIDEBAR: "TRADE_LAND_SIDEBAR",
  TRADE_ASSET_SIDEBAR: "TRADE_ASSET_SIDEBAR",
  EDIT_LAND_SIDEBAR: "EDIT_LAND_SIDEBAR",
  BUY_LAND_SIDEBAR: "BUY_LAND",
};
