import planetV from "@images/planet-v.png";
import planetW from "@images/planet-w.png";
import planetX from "@images/planet-x.png";
import planetY from "@images/planet-y.png";
import planetZ from "@images/planet-z.png";

const featuredPlanets = [
  {
    id: 1,
    name: "Planet X",
    imageUrl: planetX,
    description:
      "A metallic planet, with shimmering silver plains, jagged metal peaks, and a surface of reflective minerals",
  },
  {
    id: 2,
    name: "Planet Y",
    imageUrl: planetY,
    description:
      "An oceanic planet, with endless waves, deep trenches, and scattered floating islands",
  },
  {
    id: 3,
    name: "Planet Z",
    imageUrl: planetZ,
    price: "1.8",
    description:
      "A volcanic planet, with rivers of lava, rugged black mountains, and thick clouds of ash",
  },
  {
    id: 4,
    name: "Planet W",
    imageUrl: planetW,
    description:
      "A desert planet, with endless golden dunes, deep canyons, and scattered oases under a blazing sun",
  },
  {
    id: 5,
    name: "Planet V",
    imageUrl: planetV,
    description:
      "A crystal-covered planet, with sparkling crystalline formations, reflective valleys, and iridescent lakes",
  },
];

export default featuredPlanets;
