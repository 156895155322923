import axios from "@api/axiosInstance";

const contractService = {
  generateMintSignature(data) {
    return axios.post("/contracts/generate-mint-signature", data);
  },

  generateRoyaltySignature(data) {
    return axios.post("/contracts/generate-sale-with-royalty-signature", data);
  },
};

export default contractService;
