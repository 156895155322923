import { useCallback } from "react";

import { useMutationData } from "@api/hooks";

import { useAssets } from "@features/Asset/hooks";

import assetService from "../services";

const useDeleteAsset = (user, viewer) => {
  const { deleteAssetFromViewer } = useAssets(viewer);

  const { mutateAsync: deleteAssetAsync } = useMutationData(
    assetService.deleteAsset,
    {
      invalidateQueries: [["polygons"], ["assets"], ["owned-nfts"]],
    }
  );

  const deleteAssetFromPolygon = useCallback(
    async (assetId) => {
      if (!assetId || !viewer) {
        return;
      }
      try {
        await deleteAssetAsync(assetId);
        deleteAssetFromViewer(assetId);
      } catch (error) {
        throw new Error(error.message);
      }
    },
    [deleteAssetAsync, deleteAssetFromViewer, viewer]
  );

  return {
    deleteAssetFromPolygon,
  };
};

export default useDeleteAsset;
