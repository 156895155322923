import { useQuery } from "@tanstack/react-query";

import { userService } from "@api/services";

import { useWalletState } from "@Web3/context";

const useGetCurrentUser = () => {
  const { currentAddress } = useWalletState();
  return useQuery({
    queryKey: ["me"],
    queryFn: () => userService.getUser(currentAddress),
    enabled: !!currentAddress,
  });
};

export default useGetCurrentUser;
