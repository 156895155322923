import axiosInstance from "@api/axiosInstance";

const userService = {
  getUser(identifier) {
    return axiosInstance.get(`/users/${identifier}`);
  },

  createUser(data) {
    return axiosInstance.post("/users", data);
  },

  updateUser(data) {
    return axiosInstance.put(`/users/${data.walletAddress}`, data);
  },
};

export default userService;
