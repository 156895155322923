// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kagnb7zS {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  width: 100%;
}

.jG8u08h_ {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  padding: 0 1.5rem;
  color: #ffffff;
  margin-left: 1rem;
}

.YQIG3jPb {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 36px;
  position: relative;
  padding: 6px 24px;
  color: #ff0691;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  padding-right: 3rem;
  width: 100%;
}

.hEJXyz1L {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.hEJXyz1L > span {
  white-space: nowrap;
}

.xaMgGGv8 {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin-left: auto;
  padding-right: 0.4rem;
}

.CcheaeGK {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  padding: 6px 24px;
  display: block;
  color: #ff0691;
}

.WB1ke6Gc {
  display: block;
  text-align: left;
  padding: 6px 24px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  color: #f2f7f9;
}

.WB1ke6Gc:hover {
  background: rgba(108, 109, 114, 0.25);
}

.bDuzCev6 {
  margin: 1rem auto 0;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"walletContent": `Kagnb7zS`,
	"balanceTitle": `jG8u08h_`,
	"balance": `YQIG3jPb`,
	"token": `hEJXyz1L`,
	"usd": `xaMgGGv8`,
	"walletAddress": `CcheaeGK`,
	"disconnectWallet": `WB1ke6Gc`,
	"imageDisconnect": `bDuzCev6`
};
module.exports = ___CSS_LOADER_EXPORT___;
