import { toast } from "react-toastify";

import { useGetCurrentUser, useUpdateCurrentUser } from "@api/user/hooks";

import { useSettings } from "@features/Settings/context/SettingsContext";

import styles from "./Settings.module.css";
import SettingsSwitch from "./SettingsSwitch";
import { units } from "./constants/units";

const Settings = () => {
  const {
    settings: { measurementUnit, showLands, showAssets },
    updateSettings,
  } = useSettings();

  const { data: user } = useGetCurrentUser();

  const { mutateAsync: updateUserAsync } = useUpdateCurrentUser();

  const handleSettingChange = (key, newValue) => {
    try {
      updateSettings(key, newValue);
      toast.success("You have successfully updated settings", {
        toastId: "settingsUpdateSuccess",
      });
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while updating settings", {
        toastId: "settingsUpdateError",
      });
    }
  };

  const handleEmailNotificationChange = async (event) => {
    event.stopPropagation();
    try {
      if (user?.id) {
        await updateUserAsync({
          ...user,
          emailNotifications: !user?.emailNotifications,
        });
        toast.success(
          `You have successfully turned ${
            !user?.emailNotifications ? "on" : "off"
          } email notifications`,
          {
            toastId: "emailNotificationUpdateSuccess",
          }
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong with updating notifications", {
        toastId: "emailNotificationUpdateError",
      });
    }
  };

  return (
    <div className={styles.settingsContent}>
      <SettingsSwitch
        labelText="Units of measurements"
        twoOptions
        value={measurementUnit !== units.METERS}
        onChange={(event) => {
          event.stopPropagation();
          handleSettingChange(
            "measurementUnit",
            measurementUnit === units.METERS ? units.FEET : units.METERS
          );
        }}
      />
      <SettingsSwitch
        labelText="Send me Email notifications"
        value={user?.emailNotifications || false}
        onChange={handleEmailNotificationChange}
      />
      <SettingsSwitch
        labelText="Show lands"
        value={showLands}
        onChange={(event) => {
          event.stopPropagation();
          handleSettingChange("showLands", !showLands);
        }}
      />
      <SettingsSwitch
        labelText="Show 3D assets"
        value={showAssets}
        onChange={(event) => {
          event.stopPropagation();
          handleSettingChange("showAssets", !showAssets);
        }}
      />
    </div>
  );
};

export default Settings;
