import PropTypes from "prop-types";

import styles from "./Input.module.css";

const TextArea = ({
  id,
  name,
  label,
  placeholder,
  maxLength,
  error,
  register,
  ...rest
}) => {
  return (
    <div className={styles.container}>
      <fieldset className={styles.fieldset}>
        <legend className={styles.fieldsetLegend}>{label}</legend>
        <textarea
          id={id}
          name={name}
          className={styles.textareaInput}
          placeholder={placeholder || ""}
          maxLength={maxLength || "320"}
          {...rest}
          {...register}
        />
      </fieldset>
      {error && (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{error}</span>
        </div>
      )}
    </div>
  );
};

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.string,
  error: PropTypes.string,
  register: PropTypes.object.isRequired,
};

export default TextArea;
