export const HELP_LINKS = [
  {
    id: 1,
    label: "How to buy a Land NFT",
    url: "https://docs.decentralverse.ai/getting-started/how-to-buy-land",
  },
  {
    id: 2,
    label: "How to import 3D Asset NFTs",
    url: "https://docs.decentralverse.ai/getting-started/import-3d-assets",
  },
  {
    id: 3,
    label: "How Can You Trade?",
    url: "https://docs.decentralverse.ai/decentralverse/getting-started/how-can-you-trade-land-nfts-and-3d-asset-nfts",
  },
  {
    id: 4,
    label: "AI-Generated planets",
    url: "https://docs.decentralverse.ai/ai-generated-planets/create-your-own-planets",
  },
  {
    id: 5,
    label: "Setting up a wallet",
    url: "https://docs.decentralverse.ai/decentralverse/getting-started/setting-up-a-wallet-with-crypto",
  },
  {
    id: 6,
    label: "FAQs",
    url: "https://docs.decentralverse.ai/getting-started/faqs",
  },
  {
    id: 7,
    label: "Whitepaper",
    url: "https://docs.decentralverse.ai/decentralverse",
  },
  {
    id: 8,
    label: "The Team",
    url: "https://docs.decentralverse.ai/references/the-team",
  },
  {
    id: 9,
    label: "Roadmap",
    url: "https://docs.decentralverse.ai/whitepaper-1.0/roadmap",
  },
  {
    id: 10,
    label: "Contact Us",
    url: "https://docs.decentralverse.ai/references/contact-us",
  },
];
