import axiosInstance from "@api/axiosInstance";

const listingService = {
  getFloorPriceForNfts(nftType) {
    return axiosInstance.get(`/listing/${nftType}/floor-price`);
  },
  getHighestOffer({ nftId }) {
    return axiosInstance.get(`/offer/nft/${nftId}/highest`);
  },
  listNftForSale({ nftId, data }) {
    return axiosInstance.post(`/listing/nft/${nftId}`, data);
  },
  declineNftOffer({ nftId, offererId }) {
    return axiosInstance.post(`/offer/nft/decline/${nftId}`, {
      offererId,
    });
  },
  cancelNftListing({ nftId }) {
    return axiosInstance.delete(`/listing/nft/${nftId}`);
  },
  makeOfferForNft({ nftId, data }) {
    return axiosInstance.post(`/offer/nft/${nftId}`, data);
  },
};

export default listingService;
