// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./reset.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./global.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./components/modal.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./components/walletConnectModal.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!./components/toastify.css");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* components  */

/* third party components */
`, "",{"version":3,"sources":["webpack://./src/styles/styles.css"],"names":[],"mappings":"AAIA,gBAAgB;;AAGhB,2BAA2B","sourcesContent":["@import \"reset.css\";\n\n@import \"global.css\";\n\n/* components  */\n@import \"components/modal\";\n\n/* third party components */\n@import \"components/walletConnectModal\";\n@import \"components/toastify\";\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
