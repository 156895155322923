// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.u5T7f7m3 > div {
  max-width: 362px;
}
.byBj12S_ {
  width: 312px;
  height: 338px;

  position: absolute;
  top: 150px;
}

.mS2Lj2qM {
  outline: none;
  background: none;
  border: none;
  gap: 10px;
  width: 106px;
  height: 36px;
  background: linear-gradient(90deg, #ff0691 7.11%, #fe1650 100%);
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
}

.Az1tb4Tc {
  display: flex;
  flex-direction: column;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.25rem;
}

.GaKfZxVJ {
  text-align: left;
  color: #549fc8;
  margin-top: 0.25rem;
}

.AhsH1fpz {
  color: #ff0691;
}

.jorGjrig {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  flex-direction: row;
  gap: 0.75rem;
  width: 100%;
  height: 60px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verificationModal": `u5T7f7m3`,
	"verifyEmailRoot": `byBj12S_`,
	"modalFooterButton": `mS2Lj2qM`,
	"modalBody": `Az1tb4Tc`,
	"modalLinkUpdateEmailAddress": `GaKfZxVJ`,
	"modalColorPink": `AhsH1fpz`,
	"modalFooter": `jorGjrig`
};
module.exports = ___CSS_LOADER_EXPORT___;
