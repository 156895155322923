import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useUploadFileToS3 } from "@api/file/hooks";
import { authService } from "@api/services";
import { useUpdateCurrentUser } from "@api/user/hooks";

import { UploadImage } from "@features/Upload";
import VerificationModal from "@features/VerificationModal";

import Button from "@components/Button";
import CheckBox from "@components/Form/CheckBox/CheckBox";
import Input from "@components/Form/Input/Input";
import TextArea from "@components/Form/Input/TextArea";
import Modal from "@components/Modal";

import { useClipboard } from "@shared/hooks";
import { resizeImage } from "@shared/utils";

import copy from "@icons/copy.svg";
import uploadAvatar from "@icons/upload-avatar.png";

import styles from "./Profile.module.css";

const Profile = ({ address, user, onProfileSubmit, onClose }) => {
  const [prevEmailAddress, setPrevEmailAddress] = useState("");
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    getValues,
    setError,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: {
      id: user?.id,
      username: user?.username || "",
      email: user?.email || "",
      bio: user?.bio || "",
      walletAddress: address || "",
      emailNotifications: user ? user.emailNotifications : false,
      imageUrl: null,
      verificationCode: null,
    },
    reValidateMode: "onChange",
  });

  const { copyToClipboard } = useClipboard();

  const { mutateAsync: updateUserAsync } = useUpdateCurrentUser();

  const { mutateAsync: uploadFileToS3 } = useUploadFileToS3();

  const watchedFile = watch("imageUrl");

  const isDirty = Object.keys(dirtyFields).length > 0 || watchedFile;

  const onSubmit = async (formData) => {
    const { id, email } = formData;

    try {
      if (email) {
        const checkEmail = await authService.checkEmail(id, email);
        if (!checkEmail.valid) {
          setError("email", {
            type: "server",
            message: checkEmail.message || "Email is already in use",
          });
          return;
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || "Something went wrong, please reconnect", {
        toastId: "check-email-error",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      if (email && prevEmailAddress !== email && !user?.isVerified) {
        await authService.sendVerificationCode(email);

        setShowVerificationModal(true);
      } else {
        await handleSaveUserData(formData);
      }
    } catch (error) {
      console.error("Error sending the verification code:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    const { verificationCode, ...rest } = getValues();

    if (!verificationCode) {
      setError("verificationCode", {
        type: "custom",
        message: "Verification code is required",
      });
      return;
    }
    if (!/^\d{6}$/.test(verificationCode)) {
      setError("verificationCode", {
        type: "custom",
        message: "Enter a valid 6 digit code",
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await authService.verifyCode(
        watch("email"),
        watch("verificationCode")
      );

      if (response.valid) {
        await handleSaveUserData({ ...rest });
      } else {
        setError("verificationCode", {
          type: "server",
          message: "Verification code is invalid",
        });
      }
    } catch {
      setError("verificationCode", {
        type: "server",
        message: "Verification code is invalid or expired",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSaveUserData = async (formData) => {
    // eslint-disable-next-line no-unused-vars
    const { id, verificationCode, ...rest } = formData;
    let imageUrl = null;

    if (watchedFile) {
      try {
        const profileImageResized = await resizeImage(watchedFile, 512);

        imageUrl = await uploadFileToS3({
          fileNamePredefined: address,
          category: "user-profile",
          originalFile: watchedFile,
          fileToUpload: profileImageResized,
        });
      } catch (error) {
        toast.error(
          error?.message || "Something went wrong with uploading image!",
          { toastId: "upload-image-error" }
        );
        return;
      }
    }

    const data = {
      ...rest,
      imageUrl: imageUrl || user?.imageUrl,
      isVerified: !!rest.email,
    };

    try {
      if (id) {
        await updateUserAsync({
          ...data,
        });
        toast.success("You have successfully updated your account", {
          toastId: "update-account-success",
        });
      }
      onProfileSubmit && onProfileSubmit();
    } catch (error) {
      toast.error(
        error?.message || "Something went wrong while updating your account",
        { toastId: "update-account-error" }
      );
    }
  };

  const handleCloseVerification = () => {
    setIsSubmitting(false);
    setShowVerificationModal(false);
  };

  return (
    <>
      <Modal
        className={styles.accountModal}
        title={
          user?.id
            ? "Update your Decentralverse AI account"
            : "Create your Decentralverse AI account"
        }
        size="large"
        onClose={onClose}
      >
        <form
          className={styles.accountForm}
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className={styles.accountBody}>
            <UploadImage
              id="imageUrl"
              name="imageUrl"
              accept=".jpg, .JPG, .jpeg, .JPEG, .png, .PNG, .gif, .GIF, .GIF, .webp, .WEBP"
              register={register("imageUrl")}
              onFileChange={(event) => {
                setValue("imageUrl", event.target.files[0]);
              }}
              data={user?.imageUrl}
              defaultAvatar={uploadAvatar}
            />
            <div className={styles.containerBodyRightForm}>
              <Input
                id="username"
                name="username"
                label="Username"
                type="text"
                placeholder="Give yourself a name"
                register={register("username", {
                  minLength: {
                    value: 3,
                    message: "Username must be at least 3 characters",
                  },
                  maxLength: {
                    value: 30,
                    message: "Username must be no more than 30 characters",
                  },
                  pattern: {
                    value: /^[a-zA-Z][a-zA-Z0-9]*$/,
                    message:
                      "Username should start with a letter and contain only letters and numbers, without spaces or special characters",
                  },
                  validate: (value) => {
                    const sanitizedValue = DOMPurify.sanitize(value);

                    if (sanitizedValue !== value) {
                      return "Invalid input";
                    }
                    return true;
                  },
                })}
                error={errors.username?.message}
              />
              <Input
                id="email"
                name="email"
                label="Email"
                type="email"
                placeholder="What's your email?"
                register={register("email", {
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                  validate: (value) => {
                    const sanitizedValue = DOMPurify.sanitize(value);
                    if (sanitizedValue !== value) {
                      return "Invalid input";
                    }
                    return true;
                  },
                })}
                disabled={!!user?.email}
                error={errors.email?.message}
              />
              <TextArea
                id="bio"
                name="bio"
                label="Bio"
                placeholder="Tell the world your story!"
                maxLength="320"
                register={register("bio", {
                  validate: (value) => {
                    const sanitizedValue = DOMPurify.sanitize(value);
                    if (sanitizedValue !== value) {
                      return "Invalid input";
                    }
                    return true;
                  },
                })}
                error={errors.bio?.message}
              />
              <CheckBox
                id="emailNotifications"
                name="emailNotifications"
                label="Send me Email Notifications"
                register={register("emailNotifications")}
              />
              <div className={styles.accountWalletAddressRoot}>
                <Input
                  id="walletAddress"
                  name="walletAddress"
                  label="Wallet Address"
                  type="text"
                  disabled
                  register={register("walletAddress", {
                    validate: (value) => {
                      const sanitizedValue = DOMPurify.sanitize(value);
                      if (sanitizedValue !== value) {
                        return "Invalid input";
                      }
                      return true;
                    },
                  })}
                  error={errors.walletAddress?.message}
                />
                <img
                  onClick={() => copyToClipboard(address || "")}
                  src={copy}
                  className={styles.accountWalletAddressCopyIcon}
                />
              </div>
            </div>
          </div>
          <Button
            main
            disabled={!isDirty || isSubmitting}
            className={styles.accountFooterButton}
            loading={isSubmitting}
          >
            Save
          </Button>
        </form>
      </Modal>
      {showVerificationModal && (
        <VerificationModal
          email={watch("email")}
          onClose={handleCloseVerification}
          handleVerificationSubmit={handleVerificationSubmit}
          handleUpdateEmailAddress={() => {
            setShowVerificationModal(false);
            setPrevEmailAddress(watch("email"));
          }}
          register={register}
          errors={errors}
        />
      )}
    </>
  );
};

Profile.propTypes = {
  address: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
    bio: PropTypes.string,
    email: PropTypes.string,
    imageType: PropTypes.string,
    walletAddress: PropTypes.string,
    imageUrl: PropTypes.string,
    emailNotifications: PropTypes.bool,
    isVerified: PropTypes.bool,
  }),
  onProfileSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default Profile;
