// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D8ZqIqog {
  margin: 2rem 0;
  width: 100%;
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.Gv0PWDPR {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
}

.vkjxikgN {
  border: 1px solid #a0aec0;
  border-radius: 8px;
}

.VsAbATYh > input,
.VsAbATYh > textarea {
  font-size: 1rem;
}

.drRzjjAv {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-left: 0.5rem;
  color: #cbd5e0;
}

.JM0QpTHG {
  outline: none;
  background: none;
  border: none;
  width: 100%;
  color: #cbd5e0;
  padding: 0.5rem;
}

.VsAbATYh > input::placeholder,
.VsAbATYh > textarea::placeholder {
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1rem;
  color: #cbd5e0;
}

.zWZCORCG {
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1rem;
  outline: none;
  background: none;
  border: none;
  resize: none;
  height: 100px;
  width: 100%;
  color: #cbd5e0;
  padding: 0.5rem;
}

.zWZCORCG::-webkit-scrollbar {
  width: 3px;
}
.zWZCORCG::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
}
.zWZCORCG::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

.o4Ybm8aB {
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 0.65rem;
  color: red;
  line-height: 0.75rem;
  padding: 0.25rem 0.5rem 0.5rem;
}

.yOGkm4v6::after {
  content: "*";
  padding-left: 0.05rem;
  color: #ff0691;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `D8ZqIqog`,
	"errorContainer": `Gv0PWDPR`,
	"fieldset": `vkjxikgN`,
	"fieldSet": `VsAbATYh`,
	"fieldsetLegend": `drRzjjAv`,
	"fieldsetInput": `JM0QpTHG`,
	"textareaInput": `zWZCORCG`,
	"error": `o4Ybm8aB`,
	"required": `yOGkm4v6`
};
module.exports = ___CSS_LOADER_EXPORT___;
