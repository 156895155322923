import booleanPointInPolygon from "@turf/boolean-point-in-polygon";
import { point, polygon } from "@turf/turf";
import {
  Cartesian3,
  Cartographic,
  Cesium3DTileset,
  HeadingPitchRoll,
  Matrix3,
  Matrix4,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  Transforms,
  Math as cesiumMath,
  defined,
} from "cesium";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";

import { findPrimitiveById } from "@features/Cesium/utils/findByPrimitiveId";

const actionTypes = {
  SET_HEIGHT: "SET_HEIGHT",
  SET_ROTATION: "SET_ROTATION",
  CHANGE_POSITION: "CHANGE_POSITION",
};

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_HEIGHT:
      return { ...state, height: action.payload };
    case actionTypes.SET_ROTATION:
      return { ...state, rotation: action.payload };
    case actionTypes.CHANGE_POSITION:
      return { ...state, position: action.payload };
    case actionTypes.RESET_POSITION:
      return { ...action.payload };
    default:
      return state;
  }
}

export const useAssetAdjustment = ({
  viewer,
  initialState,
  assetId,
  polygonVertices,
}) => {
  const assetPrimitive = useRef(
    findPrimitiveById(viewer?.scene?.primitives, `asset-${assetId}`)
  );
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
  });
  const [isChangingPosition, setIsChangingPosition] = useState(false);

  const adjustHeight = useCallback(
    (adjustment) => {
      if (
        assetPrimitive.current &&
        assetPrimitive.current instanceof Cesium3DTileset
      ) {
        const newHeight = state.height + adjustment;
        dispatch({ type: actionTypes.SET_HEIGHT, payload: newHeight });

        const modelMatrix = assetPrimitive.current.modelMatrix;
        const currentPosition = Matrix4.getTranslation(
          modelMatrix,
          new Cartesian3()
        );
        const cartographic = Cartographic.fromCartesian(currentPosition);
        cartographic.height += adjustment;
        const newPosition = Cartesian3.fromRadians(
          cartographic.longitude,
          cartographic.latitude,
          cartographic.height
        );
        Matrix4.setTranslation(modelMatrix, newPosition, modelMatrix);
        viewer.scene.requestRender();
      }
    },
    [state.height, viewer.scene]
  );

  const adjustRotation = useCallback(
    (adjustment) => {
      if (
        assetPrimitive.current &&
        assetPrimitive.current instanceof Cesium3DTileset
      ) {
        const newRotation = (state.rotation + adjustment + 360) % 360;
        dispatch({ type: actionTypes.SET_ROTATION, payload: newRotation });

        const modelMatrix = assetPrimitive.current.modelMatrix;
        const position = Matrix4.getTranslation(modelMatrix, new Cartesian3());
        const heading = cesiumMath.toRadians(newRotation);
        const pitch = 0;
        const roll = 0;
        const hpr = new HeadingPitchRoll(heading, pitch, roll);
        const newModelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
          position,
          Transforms.headingPitchRollQuaternion(position, hpr),
          Matrix4.getScale(modelMatrix, new Cartesian3())
        );
        assetPrimitive.current.modelMatrix = newModelMatrix;
        viewer.scene.requestRender();
      }
    },
    [state.rotation, viewer.scene]
  );

  const changePosition = useCallback(
    (newPosition) => {
      if (
        assetPrimitive.current &&
        assetPrimitive.current instanceof Cesium3DTileset
      ) {
        const newCartographic = Cartographic.fromCartesian(newPosition);

        const modelMatrix = assetPrimitive.current.modelMatrix;
        const scale = Matrix4.getScale(modelMatrix, new Cartesian3());
        const rotation = Matrix4.getRotation(modelMatrix, new Matrix3());
        const oldPosition = Matrix4.getTranslation(
          modelMatrix,
          new Cartesian3()
        );
        const oldCartographic = Cartographic.fromCartesian(oldPosition);
        newCartographic.height = oldCartographic.height;

        const newPositionCartesian = Cartesian3.fromRadians(
          newCartographic.longitude,
          newCartographic.latitude,
          newCartographic.height
        );

        const newModelMatrix = Matrix4.fromRotationTranslation(
          rotation,
          newPositionCartesian,
          scale
        );

        assetPrimitive.current.modelMatrix = newModelMatrix;
        viewer.scene.requestRender();

        dispatch({
          type: actionTypes.CHANGE_POSITION,
          payload: [
            cesiumMath.toDegrees(newCartographic.latitude),
            cesiumMath.toDegrees(newCartographic.longitude),
          ],
        });
      }
    },
    [viewer?.scene]
  );

  useEffect(() => {
    if (viewer?.scene && isChangingPosition) {
      const handler = new ScreenSpaceEventHandler(viewer.scene.canvas);

      handler.setInputAction((click) => {
        const ray = viewer.camera.getPickRay(click.position);
        const pickedPosition = viewer.scene.globe.pick(ray, viewer.scene);

        if (!defined(pickedPosition)) {
          console.warn("No position picked.");
          return;
        }

        const pickedCartographic = Cartographic.fromCartesian(pickedPosition);
        const longDegrees = cesiumMath.toDegrees(pickedCartographic.longitude);
        const latDegrees = cesiumMath.toDegrees(pickedCartographic.latitude);
        const adjustedPosition = [latDegrees, longDegrees];
        const clickedPoint = point([longDegrees, latDegrees]);

        const correctedPolygonVertices = polygonVertices.map((coord) => [
          coord[1],
          coord[0],
        ]);
        const area = polygon([correctedPolygonVertices]);

        if (!booleanPointInPolygon(clickedPoint, area)) {
          toast.warn("3D Asset cannot be moved outside polygon");
          return;
        } else {
          changePosition(pickedPosition);

          dispatch({
            type: actionTypes.CHANGE_POSITION,
            payload: adjustedPosition,
          });
        }
      }, ScreenSpaceEventType.LEFT_CLICK);

      return () => {
        handler.destroy();
      };
    }
  }, [
    isChangingPosition,
    changePosition,
    polygonVertices,
    viewer?.scene,
    viewer?.camera,
  ]);

  const resetAdjustments = useCallback(() => {
    if (
      assetPrimitive.current &&
      assetPrimitive.current instanceof Cesium3DTileset
    ) {
      dispatch({
        type: actionTypes.RESET_POSITION,
        payload: initialState,
      });

      const initialPosition = Cartesian3.fromDegrees(
        initialState.position[1],
        initialState.position[0],
        initialState.height
      );

      const initialHeading = cesiumMath.toRadians(initialState.rotation);
      const initialPitch = 0;
      const initialRoll = 0;
      const hpr = new HeadingPitchRoll(
        initialHeading,
        initialPitch,
        initialRoll
      );

      const newModelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
        initialPosition,
        Transforms.headingPitchRollQuaternion(initialPosition, hpr),
        new Cartesian3(1, 1, 1)
      );

      assetPrimitive.current.modelMatrix = newModelMatrix;
      viewer.scene.requestRender();
    }
  }, [initialState, viewer.scene]);

  return {
    state,
    adjustHeight,
    adjustRotation,
    isChangingPosition,
    setIsChangingPosition,
    resetAdjustments,
  };
};
