import { Ion, buildModuleUrl } from "cesium";
import { Slide, ToastContainer } from "react-toastify";

import CesiumApp from "@features/Cesium/CesiumApp";
import ViewerContainer from "@features/Cesium/ViewerContainer";
import NavigationSidebar from "@features/NavigationSidebar";

import config from "./config";
import Providers from "./providers";

Ion.defaultAccessToken = config.app.cesiumApiKey;
buildModuleUrl.setBaseUrl("/cesium/");

const App = () => {
  return (
    <Providers>
      <ToastContainer
        position="top-right"
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        transition={Slide}
      />
      <ViewerContainer />
      <CesiumApp />
      <NavigationSidebar />
    </Providers>
  );
};

export default App;
