import { createElement, lazy, useEffect, useState } from "react";

const lazyWithPreload = (loadComponent) => {
  let CachedComponent = null;
  let loadingPromise = null;

  const LazyComponent = lazy(loadComponent);

  // Function to load the component and cache it
  const load = () => {
    if (!loadingPromise) {
      loadingPromise = loadComponent().then((module) => {
        CachedComponent = module.default;
      });
    }
    return loadingPromise;
  };

  // Preloadable component wrapper
  const PreloadableComponent = (props) => {
    // Hook to force re-render if component is already cached
    const [loaded, setLoaded] = useState(!!CachedComponent);

    useEffect(() => {
      if (!loaded) {
        load().then(() => setLoaded(true)); // Preload and update state
      }
    }, [loaded]);

    // Render the cached component if loaded, otherwise fallback to lazy load
    return createElement(CachedComponent || LazyComponent, props);
  };

  // Adding preload function
  PreloadableComponent.preload = load;

  return PreloadableComponent;
};

export default lazyWithPreload;
