import { useQuery } from "@tanstack/react-query";

import { getMaticToUsdRate } from "@Web3/utils";

const useMaticToUsdRate = (enabled = true) => {
  return useQuery({
    queryKey: ["usd-price"],
    queryFn: () => getMaticToUsdRate(),
    refetchInterval: 20000,
    enabled,
  });
};

export default useMaticToUsdRate;
