// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hai9jMV7 {
  position: relative;
}

.krYTSxLK {
  width: 268px;
  height: 198px;
  border: 2px dashed #a0aec0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.IC1_lcED {
  border-color: #ff0691;
}

.krYTSxLK > input {
  display: none;
  font-size: 1rem;
}

.csUFxWra {
  margin-left: 0.5rem;
  color: #cbd5e0;
}

.XvXa0rbJ {
  outline: none;
  background: none;
  border: none;
  width: 100%;
  color: #cbd5e0;
  padding: 0.5rem;
}

.W5rl7aoc > input::placeholder,
.W5rl7aoc > textarea::placeholder {
  color: #cbd5e0;
}

.IPZkRer6 {
  display: none;
}

.AGYGeYXk {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: #a0aec0;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.DH3GM6ke {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
}

.qlqPbgjC {
  display: inline-block;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: red;
  line-height: 10px;
  padding: 0.5rem;
}

.bMYrO8ns::after {
  content: "*";
  padding-left: 0.05rem;
  color: #ff0691;
}

.Sgpsw1IK {
  width: 100%;
  min-height: 8rem;
  height: auto;
  cursor: pointer;
  object-fit: contain;
}

._3eewqoP {
  max-width: 233px;
  width: 100%;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  margin: 1.5rem auto;
}
.sZtMfs1g {
  display: inline-block;
  border-radius: 0.5rem;
  width: 100%;
  background-color: #56577a;
  height: 8px;
  margin-left: 0.25rem;
}

.YD4rnoV2 {
  display: block;
  height: 100%;
  background-color: #ff0691;
  border-radius: 0.5rem;
}

.__dY_3gJ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-wrap: break-word;
}

.__dY_3gJ > span {
  font-size: 0.75rem;
  line-height: 0.85rem;
  width: 60%;
}
.__dY_3gJ > span:last-of-type {
  font-size: 0.75rem;
  line-height: 0.85rem;
  width: 35%;
}

.QrHQdWv5 {
  display: block;
  background-color: transparent;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  border: none;
  color: #ff0691;
  cursor: pointer;
  margin-top: 0.5rem;
}

.qESYJLns {
  display: flex;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  color: #fff;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.Jbg064Ef {
  cursor: auto;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hai9jMV7`,
	"fieldset": `krYTSxLK`,
	"dragging": `IC1_lcED`,
	"fieldsetLegend": `csUFxWra`,
	"fieldsetInput": `XvXa0rbJ`,
	"fieldSet": `W5rl7aoc`,
	"label": `IPZkRer6`,
	"button": `AGYGeYXk`,
	"errorContainer": `DH3GM6ke`,
	"error": `qlqPbgjC`,
	"required": `bMYrO8ns`,
	"uploadImagePreview": `Sgpsw1IK`,
	"uploadProgress": `_3eewqoP`,
	"progressBarContainer": `sZtMfs1g`,
	"progressBar": `YD4rnoV2`,
	"fileDetails": `__dY_3gJ`,
	"changeModelButton": `QrHQdWv5`,
	"percentageNumber": `qESYJLns`,
	"disabled": `Jbg064Ef`
};
module.exports = ___CSS_LOADER_EXPORT___;
