import axiosInstance from "@api/axiosInstance";

const authService = {
  getNonce(data) {
    return axiosInstance.post("/auth/nonce", data);
  },

  checkJwt() {
    return axiosInstance.get(`/auth`);
  },

  createJwt(data) {
    return axiosInstance.post("/auth", data);
  },

  deleteJwt() {
    return axiosInstance.delete(`/auth`);
  },

  sendVerificationCode(email) {
    return axiosInstance.post("/auth/send_verification_code", { email });
  },

  verifyCode(email, code) {
    return axiosInstance.post("/auth/verify_code", { email, code });
  },

  checkEmail(id, email) {
    return axiosInstance.post("/auth/check_email", { id, email });
  },
};

export default authService;
