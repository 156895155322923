import DOMPurify from "dompurify";
import PropTypes from "prop-types";
import { useRef } from "react";

import Input from "@components/Form/Input/Input";
import Modal from "@components/Modal/Modal";

import styles from "./VerificationModal.module.css";

const VerificationModal = ({
  email,
  onClose,
  register,
  errors,
  handleUpdateEmailAddress,
  handleVerificationSubmit,
}) => {
  const formRef = useRef(null);

  return (
    <Modal
      title="Verify your email"
      size="large"
      onClose={onClose}
      className={styles.verificationModal}
    >
      <div className={styles.modalBody}>
        <p>
          You need to verify your email to continue. Enter the 6 digit code
          received to{" "}
          <span className={styles.modalColorBlue}>{email || ""}</span>
        </p>
        <form ref={formRef} onSubmit={handleVerificationSubmit}>
          <Input
            id="verificationCode"
            name="verificationCode"
            label="Email verification code"
            placeholder="Enter the 6 digit code"
            register={register("verificationCode", {
              required: "Verification code is required",
              minLength: {
                value: 6,
                message: "Verification code must be 6 digits",
              },
              maxLength: {
                value: 6,
                message: "Verification code must be 6 digits",
              },
              validate: (value) => {
                const sanitizedValue = DOMPurify.sanitize(value);
                if (sanitizedValue !== value) {
                  return "Invalid input";
                }
                return true;
              },
            })}
            error={errors.verificationCode?.message}
            type="text"
            inputMode="numeric"
            maxLength="6"
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </form>
        <p>Not your correct address?</p>
        <button
          onClick={handleUpdateEmailAddress}
          className={styles.modalLinkUpdateEmailAddress}
        >
          Update your email address
        </button>
      </div>
      <div className={styles.modalFooter}>
        <button
          onClick={() => formRef.current && formRef.current.requestSubmit()}
          className={styles.modalFooterButton}
        >
          Submit
        </button>
      </div>
    </Modal>
  );
};

VerificationModal.propTypes = {
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  handleUpdateEmailAddress: PropTypes.func,
  handleVerificationSubmit: PropTypes.func,
  register: PropTypes.func,
  errors: PropTypes.object,
};

export default VerificationModal;
