import { useQuery } from "@tanstack/react-query";
import { formatUnits } from "ethers";

import { Web3QueryKeys } from "@Web3/constants";
import { useWalletState } from "@Web3/context/WalletContext";
import { convertMaticToUsd } from "@Web3/utils";

import { formatCurrency } from "@shared/helpers/formatCurrency";

import useMaticToUsdRate from "./useMaticToUsdRate";

const DECIMALS = 18;

async function fetchNativeBalance(web3, currentAddress, maticToUsdRate) {
  try {
    const nativeBalanceWei = await web3.getBalance(currentAddress);
    const nativeBalance = formatUnits(nativeBalanceWei, DECIMALS);

    const usdRate = convertMaticToUsd(nativeBalance, maticToUsdRate);

    return {
      eth: parseFloat(nativeBalance),
      wei: nativeBalanceWei,
      formatted: formatCurrency(nativeBalance),
      usd: formatCurrency(usdRate),
    };
  } catch (error) {
    console.error("Error fetching native balance", error);
    return null;
  }
}

const useNativeBalance = () => {
  const { web3, currentAddress, isCorrectChain } = useWalletState();
  const { data: maticToUsdRate } = useMaticToUsdRate();

  return useQuery({
    queryKey: [Web3QueryKeys.NATIVE_BALANCE],
    queryFn: () => fetchNativeBalance(web3, currentAddress, maticToUsdRate),
    enabled: !!currentAddress && isCorrectChain && !!web3 && !!maticToUsdRate,
  });
};

export default useNativeBalance;
