import { useQuery } from "@tanstack/react-query";

import { nftService } from "@api/services";

const useOwnedNftsQuery = (userId) => {
  const { data, ...restOfQueryParams } = useQuery({
    queryKey: ["owned-nfts"],
    queryFn: () => nftService.getOwnedNfts(userId),
    enabled: !!userId,
  });

  return {
    nfts: data?.nfts,
    ownedPolygonsCount: data?.ownedPolygonsCount || 0,
    ownedAssetsCount: data?.ownedAssetsCount || 0,
    ...restOfQueryParams,
  };
};

export default useOwnedNftsQuery;
