import config from "@config/index";
import { EthereumProvider } from "@walletconnect/ethereum-provider";

import { WALLET_CONNECT } from "@Web3/constants";
import { WalletErrorHandler } from "@Web3/utils";

const connect = async () => {
  const provider = await EthereumProvider.init({
    projectId: config.blockchain.walletConnectProjectId,
    chains: [parseInt(config.blockchain.chainId)],
    optionalChains: [parseInt(config.blockchain.chainId)],
    rpcMap: {
      [parseInt(config.blockchain.chainId)]:
        `${config.blockchain.rpcUrl}${config.blockchain.infuraApiKey}`,
    },
    showQrModal: true,
    metadata: {
      name: "Decentralverse AI",
      description: "Nft AI marketplace",
      url: "https://app.decentralverse.ai",
      icons: [
        "https://decentralverse-app.s3.eu-central-1.amazonaws.com/Decentralverse/DCAI_Logo.png",
      ],
      verifyUrl: "https://decentralverse.ai/company/privacy-policy/",
    },
    qrModalOptions: {
      themeMode: "light",
    },
  });

  try {
    await provider.enable();
    return provider;
  } catch (error) {
    const errorMessage = WalletErrorHandler(error);
    throw new Error(errorMessage);
  }
};

const connector = {
  id: WALLET_CONNECT,
  connect,
};

export default connector;
