// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ltGFQB7T {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(192px, 1fr));
  gap: 1rem;
  width: 100%;
  padding: 1rem 0.5rem;
  min-height: 180px;
  width: 100%;
}

.yU0z1P53 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  max-width: 192px;
  margin: 0 auto;
}

.u0TUX3Bk {
  position: relative;
  width: 192px;
  height: 146px;
}

.THv0zgrf {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.amUexCSs {
  filter: brightness(0.6);
}

.pnODV5v5 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.pnODV5v5 span {
  color: white;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.ouYM0yAa {
  font-family: "Inter", sans-serif;
  margin-left: 0.25rem;
  margin-right: auto;
  font-size: 0.625rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  gap: 0.25rem;
  min-height: 1rem;
  width: 100%;
  padding-right: 0.5rem;
}

.nyHNcDZv {
  flex-shrink: 0;
}

.zKfDh5QC {
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #333;
}

.gjDtTWGj {
  color: #666;
  font-family: "Inter", sans-serif;
  font-size: 0.875rem;
  font-weight: 500;
}

@keyframes aDy4SbMU {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.hohC0rCx {
  background: #1a1a1a;
  background-image: linear-gradient(
    90deg,
    #1a1a1a 0px,
    #252525 40px,
    #1a1a1a 80px
  );
  background-size: 600px 100%;
  animation: aDy4SbMU 1.2s ease-in-out infinite;
}

.i2mHmzk1 {
  width: 192px;
  height: 146px;
}

.f_CA9z3T {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.RIYO8SWL {
  width: 160px;
  height: 12px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"featuredEntityContainer": `ltGFQB7T`,
	"featuredEntityItem": `yU0z1P53`,
	"imageContainer": `u0TUX3Bk`,
	"featuredEntityThumbnail": `THv0zgrf`,
	"darkImage": `amUexCSs`,
	"comingSoonOverlay": `pnODV5v5`,
	"featuredEntityDetails": `ouYM0yAa`,
	"featuredEntityIcon": `nyHNcDZv`,
	"emptyStateContainer": `zKfDh5QC`,
	"emptyStateText": `gjDtTWGj`,
	"skeletonBase": `hohC0rCx`,
	"shimmer": `aDy4SbMU`,
	"skeletonImage": `i2mHmzk1`,
	"skeletonIcon": `f_CA9z3T`,
	"skeletonText": `RIYO8SWL`
};
module.exports = ___CSS_LOADER_EXPORT___;
