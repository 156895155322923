import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import useFeaturedAssets from "@api/asset/hooks/useFeaturedAssets";

import FeaturedEntity from "@components/FeaturedEntity";
import { Checkbox } from "@components/Form";
import Modal from "@components/WindowModal";

import storage from "@services/storage";

import styles from "./FeaturedEntitiesModal.module.css";
import featuredPlanets from "./featuredPlanets";

const FeaturedEntitiesModal = ({ handleFeaturedAssetClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { data: featuredAssets, isLoading } = useFeaturedAssets();

  const handleOpen = () => {
    const isFeatureDisabled = storage.get("featured-modal");
    if (isFeatureDisabled) {
      return;
    }
    setIsOpen(true);
  };

  useEffect(() => {
    handleOpen();
  }, []);

  const onClose = () => {
    if (isChecked) {
      storage.save("featured-modal", true);
    }

    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Welcome to the first AI-Generated Metaverse"
      modalClassName={styles.featuredModal}
      footerClassName={styles.featuredFooter}
      confirmButton={{
        text: "Start Exploring Earth",
        onClick: onClose,
      }}
    >
      <div className={styles.featuredContent}>
        <p className={styles.featuredText}>
          <strong className={styles.featuredHighlight}>Featured</strong> AI
          Generated 3D Asset NFTs
        </p>
        <FeaturedEntity
          type="asset"
          items={featuredAssets || []}
          isLoading={isLoading}
          handleItemClick={(asset) => {
            onClose();
            handleFeaturedAssetClick(asset);
          }}
        />
      </div>
      <div className={styles.featuredContent}>
        <p className={styles.featuredText}>
          <strong className={styles.featuredHighlight}>Featured</strong> AI
          Generated Planet NFTs
        </p>
        <FeaturedEntity type="planet" items={featuredPlanets} />
      </div>
      <Checkbox
        id="dont-show-again"
        name="dont-show-again"
        label="Don't show this again"
        checked={isChecked}
        onChange={setIsChecked}
      />
    </Modal>
  );
};

FeaturedEntitiesModal.propTypes = {
  handleFeaturedAssetClick: PropTypes.func.isRequired,
};

export default FeaturedEntitiesModal;
