import { useQuery } from "@tanstack/react-query";

import assetService from "../services";

const useFeaturedAssets = () => {
  return useQuery({
    queryKey: ["featured-assets"],
    queryFn: assetService.getFeaturedAssets,
  });
};

export default useFeaturedAssets;
