import axiosInstance from "@api/axiosInstance";

const fileService = {
  getPresignedUrl(data) {
    return axiosInstance.post("/file/presigned-url", data);
  },
  deleteUploadedFiles({ fileUrls }) {
    return axiosInstance.post("/file/delete-files", { fileUrls });
  },
};

export default fileService;
