// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lwmMCM0w {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.Uq20xuLI {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.Uq20xuLI::-webkit-scrollbar {
  width: 0.5rem;
  border-radius: 6px;
}

.Uq20xuLI::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}

.Uq20xuLI::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  width: 6px;
  border-radius: 6px;
}

.lwmMCM0w h3 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.25rem;
  padding: 1rem 0.5rem;
  color: #fff;
  text-wrap: nowrap;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mapContent": `lwmMCM0w`,
	"landScroll": `Uq20xuLI`
};
module.exports = ___CSS_LOADER_EXPORT___;
