import { Viewer, createWorldTerrainAsync } from "cesium";
import PropTypes from "prop-types";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import storage from "@services/storage";

/**
 * @typedef {Object} CesiumContextValue
 * @property {MutableRefObject<HTMLDivElement | null} cesiumContainerRef - The Cesium div container.
 * @property {Viewer | null} viewer - The Cesium Viewer instance.
 * @property {Function} setViewer - Function to set the Cesium Viewer instance.
 * @property {boolean} showEntities - The state determining whether polygons and assets should be shown on the map or not.
 * @property {Function} setShowEntities - Function to set the visibility of polygons and assets state.
 * @property {boolean} disableCesiumListeners - The state determining whether cesium viewer listeners should be disabled or not.
 * @property {Function} setDisableCesiumListeners - Function to disable cesium event listeners.
 */

/**
 * @type {React.Context<CesiumContextValue>}
 */

const CesiumContext = createContext();

export const useCesiumApp = () => {
  return useContext(CesiumContext);
};

const CesiumProvider = ({ children }) => {
  const cesiumContainerRef = useRef(null);
  const [viewer, setViewer] = useState(null);
  const [showEntities, setShowEntities] = useState({
    polygons: storage.get("settings")?.showLands ?? true,
    assets: storage.get("settings")?.showAssets ?? true,
  });
  const [interactionTooltip, setInteractionTooltip] = useState("");
  const [disableCesiumListeners, setDisableCesiumListeners] = useState(false);

  useEffect(() => {
    const initializeViewer = async () => {
      if (!cesiumContainerRef.current) {
        return;
      }

      const terrainProvider = await createWorldTerrainAsync();

      const newViewer = new Viewer(cesiumContainerRef.current, {
        animation: false,
        timeline: false,
        homeButton: false,
        vrButton: false,
        navigationHelpButton: false,
        sceneModePicker: false,
        baseLayerPicker: false,
        requestRenderMode: true,
        terrainProvider,
      });

      // Disable lighting for better performance
      newViewer.scene.globe.enableLighting = false;

      newViewer.scene.globe.depthTestAgainstTerrain = true;

      setViewer(newViewer);

      return () => {
        newViewer && newViewer.destroy();
      };
    };

    initializeViewer();
  }, []);

  const setInteractionTooltipText = useCallback(
    (text) => setInteractionTooltip(text),
    []
  );

  const contextValues = useMemo(
    () => ({
      cesiumContainerRef,
      viewer,
      showEntities,
      setShowEntities,
      disableCesiumListeners,
      setDisableCesiumListeners,
      interactionTooltip,
      setInteractionTooltipText,
    }),
    [
      viewer,
      showEntities,
      disableCesiumListeners,
      interactionTooltip,
      setInteractionTooltipText,
    ]
  );

  return (
    <CesiumContext.Provider value={contextValues}>
      {children}
    </CesiumContext.Provider>
  );
};

CesiumProvider.propTypes = {
  children: PropTypes.node,
};

export default CesiumProvider;
