import clsx from "clsx";
import PropTypes from "prop-types";

import aiPlanetIcon from "@icons/ai-planet.svg";
import assetIcon from "@icons/land-square.svg";

import styles from "./FeaturedEntity.module.css";

const EmptyState = () => {
  return (
    <div className={styles.featuredEntityItem}>
      <div className={styles.imageContainer}>
        <div className={styles.emptyStateContainer}>
          <span className={styles.emptyStateText}>No item to display</span>
        </div>
      </div>
      <div className={styles.featuredEntityDetails}>
        <img
          className={styles.featuredEntityIcon}
          src={assetIcon}
          alt="icon"
          width={14}
          height={14}
        />
        <p>Check back soon for new item</p>
      </div>
    </div>
  );
};

const FeaturedEntitySkeleton = () => {
  return Array(5)
    .fill(null)
    .map((_) => (
      <div key={_} className={styles.featuredEntityItem}>
        <div className={styles.imageContainer}>
          <div className={clsx(styles.skeletonImage, styles.skeletonBase)} />
        </div>
        <div className={styles.featuredEntityDetails}>
          <div className={clsx(styles.skeletonIcon, styles.skeletonBase)} />
          <div className={styles.skeletonTextContainer}>
            <div className={clsx(styles.skeletonText, styles.skeletonBase)} />
            <div
              className={clsx(
                styles.skeletonText,
                styles.skeletonBase,
                styles.shortText
              )}
            />
          </div>
        </div>
      </div>
    ));
};

const FeaturedEntity = ({ type, items, isLoading, handleItemClick }) => {
  const isPlanet = type === "planet";
  const Component = isPlanet ? "div" : "button";

  if (isLoading) {
    return (
      <div className={styles.featuredEntityContainer}>
        <FeaturedEntitySkeleton />
      </div>
    );
  }

  if (!items.length) {
    return (
      <div className={styles.featuredEntityContainer}>
        <EmptyState />
      </div>
    );
  }

  return (
    <div className={styles.featuredEntityContainer}>
      {items?.map((item) => (
        <Component
          key={item.id}
          className={styles.featuredEntityItem}
          onClick={() => {
            if (!isPlanet && handleItemClick) {
              handleItemClick(item);
            }
          }}
        >
          <div className={styles.imageContainer}>
            <img
              src={item.imageUrl}
              alt={item.name}
              className={clsx(styles.featuredEntityThumbnail, {
                [styles.darkImage]: isPlanet,
              })}
            />
            {isPlanet && (
              <div className={styles.comingSoonOverlay}>
                <span>Coming Soon</span>
              </div>
            )}
          </div>
          <div className={styles.featuredEntityDetails}>
            <img
              className={styles.featuredEntityIcon}
              src={!isPlanet ? assetIcon : aiPlanetIcon}
              alt="icon"
              width={14}
              height={14}
            />
            <p>{item.description || "No description provided"}</p>
          </div>
        </Component>
      ))}
    </div>
  );
};

FeaturedEntity.propTypes = {
  type: PropTypes.oneOf(["asset", "planet"]).isRequired,
  items: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  handleItemClick: PropTypes.func,
};

export default FeaturedEntity;
