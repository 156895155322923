import {
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  defined as cesiumDefined,
} from "cesium";
import { useCallback, useEffect, useRef } from "react";

const useEntityHoverPreload = ({
  viewer,
  preloadModal,
  preloadSidebar,
  userId,
  interval = 1500,
  enabled = true,
}) => {
  const preloadedComponents = useRef({
    modal: false,
    sidebar: false,
  });

  const checkEntityHover = useCallback(
    (event) => {
      const pickedObject = viewer.scene.pick(event.endPosition);

      if (cesiumDefined(pickedObject) && cesiumDefined(pickedObject.id)) {
        const ownerId = pickedObject.id.properties?.ownerId?.getValue();
        const isOwner = ownerId === userId;

        if (!preloadedComponents.current.modal && !isOwner) {
          preloadModal();
          preloadedComponents.current.modal = true;
        }

        if (!preloadedComponents.current.sidebar && isOwner) {
          preloadSidebar();
          preloadedComponents.current.sidebar = true;
        }
      }
    },
    [preloadModal, preloadSidebar, userId, viewer?.scene]
  );

  useEffect(() => {
    if (
      !viewer?.canvas ||
      !viewer?.scene ||
      !enabled ||
      (preloadedComponents.current.modal && preloadedComponents.current.sidebar)
    ) {
      return;
    }

    const handler = new ScreenSpaceEventHandler(viewer.canvas);

    const trackMousePosition = () => {
      handler.setInputAction(checkEntityHover, ScreenSpaceEventType.MOUSE_MOVE);
    };

    const intervalId = setInterval(trackMousePosition, interval);

    return () => {
      clearInterval(intervalId);
      handler.destroy();
    };
  }, [
    viewer,
    preloadModal,
    preloadSidebar,
    enabled,
    interval,
    checkEntityHover,
  ]);
};

export default useEntityHoverPreload;
