// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DoGluAbt {
  max-width: 24rem;
}

.Q5h59huF {
  font-size: 0.85rem;
  line-height: 1.25rem;
  padding: 0 1rem;
}

.sQHlygz7 {
  margin-top: 1rem;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"welcomeModal": `DoGluAbt`,
	"content": `Q5h59huF`,
	"greetings": `sQHlygz7`
};
module.exports = ___CSS_LOADER_EXPORT___;
