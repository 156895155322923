import { useQuery } from "@tanstack/react-query";

import assetService from "../services";

const useAssetTypesQuery = () => {
  return useQuery({
    queryKey: ["assetTypes"],
    queryFn: () => assetService.getAssetTypes(),
  });
};

export default useAssetTypesQuery;
