import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "/api",
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    const originalRequest = error.config;

    let serverError = error;
    if (error.response && error.response.data) {
      serverError = error.response.data;
    } else if (error.message) {
      serverError = error;
    }

    if (originalRequest.url === "/auth/refresh-token") {
      return Promise.reject(serverError);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        await axiosInstance.post("/auth/refresh-token");
        return axiosInstance(originalRequest);
      } catch {
        return Promise.reject(serverError);
      }
    }

    return Promise.reject(serverError);
  }
);

export default axiosInstance;
