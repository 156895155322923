// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dc_Yzg4M,
.dlDfz96n,
.Xf3WPEOs {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.v17kPiLJ {
  padding: 1.5rem 0;
  cursor: pointer;
}
.lsT_Wn3q {
  height: 100%;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(1, 5, 25, 0.9);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.dlDfz96n {
  padding: 1.75rem 0;
  width: 100%;
}

.dlDfz96n button {
  margin-bottom: 3rem;
}

.Xf3WPEOs button {
  margin-bottom: 3rem;
}

.HDNix2Be img {
  object-fit: cover;
  border-radius: 50%;
}

.qw2W46sj {
  position: relative;
}
.GNbouRAJ {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #000;
  filter: invert(35%) sepia(70%) saturate(7179%) hue-rotate(318deg)
    brightness(102%) contrast(106%);
  top: 0;
  right: 0;
}

.PCrUsr5y {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  position: relative;
}
.PCrUsr5y > img {
  margin-right: -0.5rem;
}

.PCrUsr5y:hover {
  background: rgba(108, 109, 114, 0.25);
  text-decoration: none;
}

.f9ZQc5AC {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  height: 100%;
  background: rgba(11, 14, 29, 0.8);
  left: 82px;
  top: 0;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
  z-index: -1;
  overflow-x: hidden;
}

.f9ZQc5AC.YVrREw53 {
  transform: translateX(-342px);
}

.xAVSh3OO {
  width: 100%;
  padding: 1.5rem 0.5rem 0;
  position: relative;
  margin-top: 1rem;
  margin-bottom: 3.2rem;
}

.oF9El58D {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 1.25rem;
  line-height: 2rem;
  color: #fff;
}

.xAVSh3OO .Id_6JvGo {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 0.25rem;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  position: absolute;
  right: 8px;
  bottom: 12px;
}

.M3TW4hee {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-size: cover;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topOptions": `Dc_Yzg4M`,
	"optionsWrapper": `dlDfz96n`,
	"loginWrapper": `Xf3WPEOs`,
	"logoBtn": `v17kPiLJ`,
	"sidebar": `lsT_Wn3q`,
	"hasUserImage": `HDNix2Be`,
	"hasNewNotifications": `qw2W46sj`,
	"newNotifications": `GNbouRAJ`,
	"visitLink": `PCrUsr5y`,
	"sidebarContent": `f9ZQc5AC`,
	"hideContent": `YVrREw53`,
	"titleWrapper": `xAVSh3OO`,
	"logoSignature": `oF9El58D`,
	"arrow": `Id_6JvGo`,
	"avatar": `M3TW4hee`
};
module.exports = ___CSS_LOADER_EXPORT___;
