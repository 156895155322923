// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EZikIB5B {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000;
  overflow-y: auto;
}

.VgntMCqz {
  display: flex;
  flex-direction: column;
  width: auto;
  color: #fff;
  background: #010519;
  max-height: calc(100vh - 2rem);
  box-shadow:
    0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  padding: 1.5rem;
  border-radius: 0.5rem;
  margin: auto;
}

.k02EZZCa {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-shrink: 0;
}

.xkyabRUX {
  font-family: "Lato", sans-serif;
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.5rem;
  margin: 0;
}

.VaaPlLGp {
  display: block;
  font-size: 0.75rem;
  color: #666;
  margin-top: 0.5rem;
}

.g0juFI4i {
  width: 1rem;
  height: 1rem;
  padding-top: 0.25rem;
  background: none;
  border: none;
  cursor: pointer;
  &:hover {
    transform: scale(1.3);
  }
}

.RBOtwlve {
  padding-bottom: 1.5rem;
  margin-top: 1rem;
  overflow-y: auto;
  flex: 1;
  margin-right: -0.5rem;
  padding-right: 0.5rem;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 4px;
  }
}

.lky_oqDJ {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
  gap: 1rem;
  margin-top: 1rem;
  flex-shrink: 0;
}

.lky_oqDJ .csrNulJD {
  grid-column: 1 / 2;
  justify-self: start;
}

.lky_oqDJ .OztJJaIz {
  justify-self: end;
  grid-column: 2 / 3;
}

.ohUaGSP3,
.yV2MmZm7 {
  display: block;
  border: 1px solid #2a2a2a;
  margin-bottom: 1rem;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `EZikIB5B`,
	"modal": `VgntMCqz`,
	"header": `k02EZZCa`,
	"title": `xkyabRUX`,
	"subtitle": `VaaPlLGp`,
	"closeButton": `g0juFI4i`,
	"body": `RBOtwlve`,
	"footer": `lky_oqDJ`,
	"cancelButton": `csrNulJD`,
	"confirmButton": `OztJJaIz`,
	"headerDivider": `ohUaGSP3`,
	"footerDivider": `yV2MmZm7`
};
module.exports = ___CSS_LOADER_EXPORT___;
